<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-template"></i>{{ $t("Image on Template") }}
    </h2>
    <v-row class="mt-12">
      <v-col cols="4">
        <h3 class="page-sub-title-bar" style="font-weight: bold">
          {{ $t("AREA LIST") }}
        </h3>
        <!--
        <v-text-field outlined dense hide-details solo
        placeholder="Input the store code"
        prepend-inner-icon="fa-search"
        class="form-input ico-search mt-5"
        >
        </v-text-field>
        -->
        <div class="select_store_box mt-5">
          <h4 class="select_article is-complete">{{ $t("Company") }}</h4>
          <div class="forms-area">
            <v-select
              v-model="selectedCompany"
              :items="companyItems"
              :label="`${$t('Select the company')}`"
              item-text="companyItems.company"
              item-value="companyItems.company"
              @change="changeedCompany()"
              :disabled="disabledCompany"
              clearable:false
              dense
              flat
              hide-details
              solo
            ></v-select>
          </div>
        </div>
        <div class="select_store_box mt-5">
          <h4 ref="country" class="select_article">{{ $t("Country") }}</h4>
          <div class="forms-area">
            <v-select
              v-model="selectedCountry"
              :items="countryItems"
              :label="`${$t('Select the country')}`"
              @change="changeedCountry();Flagchange('country')"
              clearable:false
              dense
              flat
              hide-details
              solo
              clearable
            ></v-select>
          </div>
        </div>
        <div class="select_store_box">
          <h4 ref="region" class="select_article">{{ $t("Region") }}</h4>
          <div class="forms-area">
            <v-select
              v-model="selectedRegion"
              :items="regionItems"
              :label="`${$t('Select the region')}`"
              @change="changeedRegion();Flagchange('region')"
              clearable:false
              dense
              flat
              hide-details
              solo
              clearable
            ></v-select>
          </div>
        </div>
        <div class="select_store_box">
          <h4 ref="city" class="select_article">{{ $t("City") }}</h4>
          <div class="forms-area">
            <v-select
              v-model="selectedCity"
              :items="cityItems"
              :label="`${$t('Select the city')}`"
              @change="changeedCity();Flagchange('city')"
              clearable:false
              dense
              flat
              hide-details
              solo
              clearable
            ></v-select>
          </div>
        </div>
        <div class="select_store_box">
          <h4 ref="store" class="select_article">{{ $t("Store") }}</h4>
          <div class="forms-area list">
            <!-- <v-select
              v-model="selectedStore"
              :items="storeItems"
              :label="`${$t('Select the city')}`"
              item-value="code" item-text="name"
              @change="changeedCity()"
              clearable:false
              dense
              flat
              hide-details
              solo
              clearable
            ></v-select> -->
            <v-list>
              <v-list-item-group
                v-model="selectedStore"
                active-class="list-select"
              >
                <v-list-item
                  @change="changeedStore(item.code)"
                  v-for="(item, i) in storeItems"
                  :key="i"
                  :value="item.code + ',' + item.name"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.name + '(' + item.code + ')'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </v-col>
      <v-col cols="5">
        <v-row>
          <div class="searchArea" style="margin-top: 25px">
            <v-text-field
              v-model="searchBy.fileName"
              @keyup.enter="searchGroups"
              :placeholder="$t('Input name')"
              class="form-input"
              outlined
              dense
              hide-details
              clearable
              @click:clear="resetsearch"
            ></v-text-field>
            <v-btn @click="searchGroups" text class="btn type-search ml-3">{{
              $t("Search")
            }}</v-btn>
          </div>
        </v-row>

        <!-- Template group list -->
        <v-data-table
          style="margin-left: -13px; margin-top: 15px"
          :headers="imageHeaders"
          v-model="selected"
          item-key="fileName"
          :items="initaldata"
          show-select
          :options.sync="options"
          :hide-default-footer="true"
          class="type3 tbl-type01 mt-10"
          height="420"
          :item-class="getRowClass"
          :items-per-page="10"
          :single-select="false"
          @click:row="getImagePreview"
        >
          <template slot="no-data">
            <p>{{ $t("No data available") }}</p>
          </template>
        </v-data-table>
        <!-- Pagination -->

        <div class="pageInfotext">{{ pageInfoText }}</div>
        <div style="padding: 14px">
          <v-pagination
            v-model="page"
            @input="paging"
            :length="totalPages * 1"
            color="#2f3b4c"
            class="pageAlign"
          ></v-pagination>
        </div>
        <br />
        <br />
        <!-- Button group -->
        <div class="" style="margin-left: -20px; margin-top: -48px">
          <div>
            <!-- <new-template-group
              :newBtnDisabled="newBtnDisabled"
              :user="user"
              :tagTypes="tagTypes"
              @resetGroups="resetGroups"
              @fireConfirm="confirm"
              :templateTypeList="templateTypeList"
              @getSearchData ='getSearchData($event)'
            />
            <edit-template-group
              :editDisabled="editBtnDisabled"
              :user="user"
              :tagTypes="tagTypes"
              :group="selectedGroup"
              :templates="templates"
              @resetGroups="resetGroups"
              @refreshTemplates="refreshTemplates"
              :templateTypeList="templateTypeList"
              :selectedTemplateList="selectedTemplateList"
               @getSearchData ='getSearchData($event)'
            /> -->
            <file-upload
              class="btn-s ml-2"
              :imagedetail="imagedetail"
              :btnDisabledFileUpload="btnDisabledFileUpload"
              @callTorefreah="torefresh"
            />
            <v-btn
              class="btn-s ml-2"
              :disabled="selected.length === 0 ||btnDisabledFileUpload "
              @click="Todeleteimages"
              text
              >{{ $t("Delete") }}</v-btn
            >
          </div>
          <!-- Pagination -->
          <!-- <div>
            <div class="pageInfo">{{ groupPageInfoText }}</div>
            <v-pagination
              v-model="groupPage"
              @input="groupPaging"
              :length="groupTotalPages*1"
              :total-visible="groupTotalVisiblePages"
              color="#2f3b4c"
            >
            </v-pagination>
          </div> -->
        </div>
      </v-col>
      <v-col cols="3">
        <div class="customInfo" style="margin-top: 91px">
          <div class="info-preview" style="width: 480px">
            <h4 class="tit">{{ $t("PREVIEW") }}</h4>
            <div class="preview">
              <img :src="imageContent" class="img-label" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import config from '@/plugins/configs'
import codes from '@/plugins/codes'
import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus.js'
import FileUpload from './modal/FileUpload.vue'
// Mixins
import Pagenation from '@/mixins/Pagenation'

const requests = {
  imagelist: {
    method: 'get',
    url: '/api/common/images/list'
  },
  Selectimagepreview: {
    method: 'get',
    url: '/api/common/images/selection'
  },
  deleteImages: {
    method: 'delete',
    url: '/api/common/images/delete/selection'
  },
  getTagTypes: {
    method: 'get',
    url: '/api/common/templates/data'
  }
}
export default {
  mixins: [Pagenation],
  components: {
    FileUpload
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      dialog_UserPopup: false,
      buttonDisabled: false,
      page: 1,
      pageCount: 0,
      selectedSaveUsers: [],
      disabledCompany: true,
      selectedCompany: '',
      selectedCountry: '',
      selectedRegion: '',
      selectedCity: '',
      selectedStoreCode: '',
      selectedStoreName: '',
      searchStoreItems: [],
      rtnStore: {},
      selectedStore: '',
      companyItems: [],
      countryItems: [],
      regionItems: [],
      cityItems: [],
      storeItems: [],
      toselectedcountry: [],
      toselectedregion: [],
      toselectedcity: [],
      toselectedstore: [],
      areaparamlist: '',
      countrylist: '',
      areaid: '',
      disableappsettingsbutton: false,
      permissionkey: '',
      permissionvalue: '',
      changeStoreActionFlag: false,
      reloadcust: false,
      reloadfav: false,
      searchBy: { fileName: '' },
      imageContent: null,
      options: {},
      initaldata: [],
      totalPages: 1,
      pageInfoText: '',
      totalVisiblePages: 5,
      selected: [],
      imagedetail: {},
      btnDisabledFileUpload: false,
      flagdata: '',
      btndisabled: false
    }
  },

  mounted () {
    // Disable buttons
    this.$store.dispatch('auth/getDisabledBtn', '9300').then((flag) => {
      this.appConfigSaveDisabled = flag
    })
    this.clearTabindex()
    this.permissionkey = this.$store.state.dataStore.permissionKey
    this.permissionvalue = this.$store.state.dataStore.permissionValue

    console.log(this.permissionkey)
    console.log(this.permissionvalue)
    const storecode = this.$store.state.dataStore.managedStores
    console.log(storecode)
    this.togetareaparam()

    if (this.permissionkey === 'store') {
      console.log(this.selectedStore)
      this.togetimagelist(this.permissionvalue, this.page)
      // this.changeedStore(this.permissionvalue)
      this.areaId = this.permissionvalue
      this.setCompany()
      this.setCountry()
      this.setRegion()
      this.setCity()
      this.setStore()
    } else {
      this.setCompany()
      this.setCountry()
      this.togetimagelist(this.areaidforcompany, this.page)
    }

    // init
    if (this.productionType === 'cloud') {
      this.disabledCompany = false
    }
    //  User Mapping : Add / Delete
    this.$store.dispatch('auth/getDisabledBtn', '5400').then((flag) => {
      if (flag) {
        this.btndisabled = flag
        this.btnDisabledFileUpload = flag
      } else {
        if (this.permissionkey === 'store' && this.selectedStore === '') {
          this.btnDisabledFileUpload = true
        } else if (this.permissionkey === 'company' && this.selectedCompany === '') {
          this.btnDisabledFileUpload = true
        } else if (this.permissionkey === 'city' && this.selectedCity === '') {
          this.btnDisabledFileUpload = true
        } else if (this.permissionkey === 'country' && this.selectedCountry === '') {
          this.btnDisabledFileUpload = true
        } else if (this.permissionkey === 'region' && this.selectedRegion === '') {
          this.btnDisabledFileUpload = true
        } else {
          this.btnDisabledFileUpload = flag
        }
      }
    })

    // 상단 공통 팝업 & 자동완성기능 사용유무
    EventBus.$emit('enableSelectedStores', false)

    // seleted Store 값이 없으면 Popup
    // if (!this.$store.getters['dataStore/IS_SELECTED_STORE']) {
    //   EventBus.$emit('openSelectedStores')
    // }

    // this.setCountry()

    if (this.selectedCity !== '') this.$refs.city.classList.add('is-complete')
  },
  computed: {
    imageHeaders () {
      return [
        { text: this.$t('Image Name'), value: 'fileName' },
        { text: this.$t('Last Update Time'), value: 'lastModified' }
      ]
    },
    btnDisabledDelete () {
      return this.btnDisabledAddDelete || this.selected.length < 1
    }
  },
  watch: {
    options: {
      handler () {
        this.togetimagelist(this.areaid, this.page)
        // this.page = 1
      },
      deep: true
    }
  },
  methods: {
    torefresh () {
      this.togetimagelist(this.areaid, 1)
    },
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    getRowClass () {
      return codes.ROW_CLASS
    },
    // reset data
    resetsearch () {
      this.searchBy.fileName = null
      this.searchBy.fileName = ''
      this.page = 1
      this.togetimagelist(this.areaid, this.page)

      //   this.getGroups(1)
    },
    searchGroups () {
      console.log(this.searchBy)

      if (this.searchBy.fileName !== '') {
        console.log(this.page)
        this.togetimagelist(this.areaid, 1)
      }
    },

    getTemplateSortKey (sortKey) {
      switch (sortKey) {
        case 'fileName':
          sortKey = 'fileName'
          break
        case 'lastModified':
          sortKey = 'lastModified'
          break
        default:
          break
      }
      return sortKey
    },

    async togetimagelist (id, page) {
      console.log(id)
      this.areaid = id
      this.selected = []
      console.log(this.selectedStore.length)
      console.log(typeof this.selectedStore)
      try {
        var config
        if (!this.changeStoreActionFlag) {
          if (this.permissionkey === 'store') {
            config = {
              params: {
                areaId: null,
                stationCode: id
              }
            }
          } else {
            if (this.permissionkey === null) {
              config = {
                params: {
                  areaId: null,
                  stationCode: null
                }
              }
            } else {
              config = {
                params: {
                  areaId: id,
                  stationCode: null
                }
              }
            }
          }
        } else {
          if (this.selectedStore.length > 0) {
            console.log('iffff')
            config = {
              params: {
                areaId: null,
                stationCode: id
              }
            }
          }
          if (
            this.selectedStore.length === 0 ||
            this.selectedStore.length === undefined
          ) {
            console.log('elseeee')
            config = {
              params: {
                areaId: id,
                stationCode: null
              }
            }
          }
        }
        console.log(config)

        if (this.searchBy.fileName !== '') {
          console.log('if sta')
          config.params.fileName = this.searchBy.fileName
          config.params.page = page - 1
          config.params.size = 10
        } else {
          console.log('else sta')
          config.params.fileName = null
          config.params.size = 10
          config.params.page = page - 1
        }
        // if(this.selectedStore)

        const { sortBy, sortDesc } = this.options

        let sort = null
        if (commons.isSorting(sortBy, sortDesc)) {
          const sortKey = this.getTemplateSortKey(sortBy[0])
          const sortOrder = sortDesc[0]
          sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
        }
        if (sort !== null) config.params.sort = sort

        this.$utils
          .callAxios(requests.imagelist.method, requests.imagelist.url, config)
          .then((res) => {
            console.log(res)
            if (res.data !== '') {
              this.initaldata = this.converttempimageDate(res.data)
              // this.selected.push(this.initaldata[0])
              this.pageInfoText = commons.getPageInfoText(res.headers)
              this.totalPages = Number(res.headers['x-totalpages'])
              console.log(this.totalPages)
              this.getImagePreview(this.initaldata[0])
            } else {
              console.log(config.params)
              this.initaldata = []
              const headers = {}
              this.imagedetail = config.params
              headers['x-totalelements'] = 0
              this.pageInfoText = commons.getPageInfoText(headers)
              this.totalPages = 0
              this.selected = []
              this.imageContent = null
            }
          })
          .catch((error) => {
            console.log(error)
            this.initaldata = []
            const headers = {}
            headers['x-totalelements'] = 0
            this.pageInfoText = commons.getPageInfoText(headers)
            this.totalPages = 0
            this.selected = []
            this.imageContent = null
            console.debug(error)
          })
      } catch (error) {
        console.log(error)
        this.initaldata = []
        const headers = {}
        headers['x-totalelements'] = 0
        this.pageInfoText = commons.getPageInfoText(headers)
        this.totalPages = 0
        this.selected = []
        this.imageContent = null
        console.debug(`Could not find any image. error: ${error}`)
        console.warn(`Could not find any image. error: ${error}`)
      }
    },
    converttempimageDate (data) {
      return data.map((data) => {
        data.lastModified = commons.convertDate(data.lastModified)
        return data
      })
    },
    // get preview deatils based on store
    getImagePreview (item, row) {
      console.log(item)
      console.log(row)
      this.imagedetail = item
      this.imageContent = null
      // if (row !== undefined) {
      //   row.select(true)
      // }
      const params = { id: item.id }
      const config = { params: params }

      this.$utils
        .callAxios(
          requests.Selectimagepreview.method,
          requests.Selectimagepreview.url,
          config
        )
        .then((res) => {
          // this.imagedetail = {
          //   created: res.data.created,
          //   fileName: res.data.fileName,
          //   id: res.data.id,
          //   name: res.data.id,
          //   stationCode: res.data.areaId,
          //   areaId: res.data.areaId
          // }
          var baseImage = res.data.content
          this.imageContent = baseImage
          this.selected = []
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    paging (page = this.page) {
      this.page = page
      this.togetimagelist(this.areaid, this.page)
    },

    Todeleteimages () {
      console.log('Delete page')
      console.log(this.selected)
      const data = this.selected.map((imageid) => imageid.id)
      console.log(data)
      const config = {
        params: {
          idList: data + '' /// Focus on adding''to the array, which becomes "1, 2, 3"
        }
      }
      console.log(config)
      this.$utils
        .callAxios(
          requests.deleteImages.method,
          requests.deleteImages.url,
          config
        )
        .then((res) => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.togetimagelist(this.areaid, 1)
        })
        .catch((error) => {
          console.debug(`Failed to delete products. error: ${error}`)
        })
    },

    // AREA SELECTION PART
    togetareaparam () {
      const config = { params: {} }
      this.$utils
        .callAxios(
          codes.requests.getAreas.method,
          codes.requests.getAreas.url,
          config
        )
        .then((res) => {
          console.log(res)
          this.areaparamlist = res.data.areaParamList
          console.log(this.areaparamlist)
          this.changeedCompany()
          if (this.permissionkey === 'store') {
            this.changeedCompany()
            this.changeedCountry()
            this.changeedRegion()
            this.changeedCity()
            this.changeedStore(this.storeItems[0].code)
          }
        })
    },
    // toselectedcountry:[],
    // toselectedregion:[],
    // toselectedcity:[],
    // toselectedstore:[],
    Flagchange (data) {
      this.flagdata = data
      if (data === 'country') {
        this.selectedRegion = ''
        this.regionItems = []
        this.setRegion()
      }
      if (data === 'region') {
        this.selectedCity = ''
        this.cityItems = []
        this.setCity()
      }
      if (data === 'city') {
        this.selectedStore = ''
        this.storeItems = []
        this.setStore()
      }
    },
    changeedCompany () {
      this.page = 1
      this.selectedStore = ''
      const obj = [
        {
          city: '',
          company: this.selectedCompany,
          country: '',
          region: ''
        }
      ]
      this.countrylist = obj
      const result1 = this.areaparamlist.filter(function (o1) {
        return obj.some(function (o2) {
          return (
            o1.company === o2.company &&
            o1.country === o2.country &&
            o1.region === o2.region &&
            o1.city === o2.city
          ) // id is unnique both array object
        })
      })
      console.log(result1.length)
      if (result1.length !== 0) {
        this.areaidforcompany = result1[0].areaId
        console.log(this.areaidforcompany)
        this.togetimagelist(this.areaidforcompany, this.page)
      } else {
        this.togetimagelist(this.areaidforcompany, this.page)
      }
      this.selectedCountry = ''
      this.selectedRegion = ''
      this.selectedCity = ''
      this.selectedStore = ''
      this.regionItems = []
      this.cityItems = []
      this.storeItems = []
      this.setCountry()
    },
    changeedCountry () {
      this.page = 1
      this.selectedStore = ''
      console.log(this.selectedCompany)
      console.log(this.selectedCountry)
      if (this.btndisabled) {
        this.btnDisabledFileUpload = this.btndisabled
      } else {
        if (this.permissionkey === 'country' && this.selectedCountry !== '' && this.selectedCountry !== undefined) {
          this.btnDisabledFileUpload = false
        } else {
          if (this.permissionkey === 'company') {
            this.btnDisabledFileUpload = false
          } else {
            this.btnDisabledFileUpload = true
          }
        }
      }
      const obj = [
        {
          city: '',
          company: this.selectedCompany,
          country: this.selectedCountry,
          region: ''
        }
      ]
      this.countrylist = obj
      console.log(this.countrylist)
      console.log(this.areaparamlist)
      const result1 = this.areaparamlist.filter(function (o1) {
        return obj.some(function (o2) {
          return (
            o1.company === o2.company &&
            o1.country === o2.country &&
            o1.region === o2.region &&
            o1.city === o2.city
          ) // id is unnique both array object
        })
      })
      console.log(result1.length)
      if (result1.length !== 0) {
        this.areaidforcountry = result1[0].areaId
        console.log(this.areaidforcountry)
        this.togetimagelist(this.areaidforcountry, this.page)
      } else {
        this.togetimagelist(this.areaidforcompany, this.page)
      }

      this.selectedRegion = ''
      this.selectedCity = ''
      this.selectedStore = ''
      this.regionItems = []
      this.cityItems = []
      this.storeItems = []
      this.setRegion()
    },
    changeedRegion () {
      this.page = 1
      this.selectedStore = ''
      console.log(this.selectedCompany)
      console.log(this.selectedCountry)
      if (this.btndisabled) {
        this.btnDisabledFileUpload = this.btndisabled
      } else {
        if (this.permissionkey === 'region' && this.selectedRegion !== '' && this.selectedRegion !== undefined) {
          this.btnDisabledFileUpload = false
        } else {
          if (this.permissionkey === 'company' || this.permissionkey === 'country') {
            this.btnDisabledFileUpload = false
          } else {
            this.btnDisabledFileUpload = true
          }
        }
      }
      const obj = [
        {
          city: '',
          company: this.selectedCompany,
          country: this.selectedCountry,
          region: this.selectedRegion
        }
      ]

      this.countrylist = obj
      console.log(this.countrylist)
      console.log(this.areaparamlist)
      const result2 = this.areaparamlist.filter(function (o1) {
        return obj.some(function (o2) {
          return (
            o1.company === o2.company &&
            o1.country === o2.country &&
            o1.region === o2.region &&
            o1.city === o2.city
          ) // id is unnique both array object
        })
      })

      if (result2.length !== 0) {
        console.log(result2)
        this.areaidforregion = result2[0].areaId
        console.log(this.areaidforregion)
        this.togetimagelist(this.areaidforregion, this.page)
      } else {
        this.togetimagelist(this.areaidforcountry, this.page)
      }

      this.selectedCity = ''
      this.selectedStore = ''
      this.cityItems = []
      this.storeItems = []
      this.setCity()
    },
    changeedCity () {
      this.page = 1
      this.selectedStore = ''
      console.log(this.selectedCompany)
      console.log(this.selectedCountry)
      if (this.btndisabled) {
        this.btnDisabledFileUpload = this.btndisabled
      } else {
        if (this.permissionkey === 'city' && this.selectedCity !== '' && this.selectedCity !== undefined) {
          this.btnDisabledFileUpload = false
        } else {
          console.log('this.permissionkey' + this.permissionkey)
          if (this.permissionkey === 'company' || this.permissionkey === 'region' || this.permissionkey === 'country') {
            this.btnDisabledFileUpload = false
          } else {
            this.btnDisabledFileUpload = true
          }
        }
      }
      const obj = [
        {
          city: this.selectedCity,
          company: this.selectedCompany,
          country: this.selectedCountry,
          region: this.selectedRegion
        }
      ]

      this.countrylist = obj
      console.log(this.countrylist)
      console.log(this.areaparamlist)
      const result3 = this.areaparamlist.filter(function (o1) {
        return obj.some(function (o2) {
          return (
            o1.company === o2.company &&
            o1.country === o2.country &&
            o1.region === o2.region &&
            o1.city === o2.city
          ) // id is unnique both array object
        })
      })

      if (result3.length !== 0) {
        console.log(result3)
        this.areaidforcity = result3[0].areaId
        console.log(this.areaidforcity)
        this.togetimagelist(this.areaidforcity, this.page)
      } else {
        this.togetimagelist(this.areaidforregion, this.page)
      }
      this.selectedStore = ''
      this.storeItems = []
      this.setStore()
    },
    changeedStore (code) {
      this.page = 1
      this.changeStoreActionFlag = true
      this.selectedStore = code
      if (this.btndisabled) {
        this.btnDisabledFileUpload = this.btndisabled
      } else {
        if (this.permissionkey === 'store' && this.selectedStore !== '' && this.selectedStore !== undefined) {
          this.btnDisabledFileUpload = false
        } else {
          if (this.permissionkey === 'company' || this.permissionkey === 'region' || this.permissionkey === 'city' || this.permissionkey === 'country') {
            this.btnDisabledFileUpload = false
          } else {
            this.btnDisabledFileUpload = true
          }
        }
      }
      this.togetimagelist(this.selectedStore, this.page)
    },
    saveRequestConfig: function (config) {
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: config.params
      }
      this.requestConfig = requestConfig
    },
    async setCompany () {
      this.searchStoreItems = this.$store.state.dataStore.managedStores
      console.log(this.searchStoreItems)

      this.searchStoreItems.forEach((store) => {
        if (this.companyItems.indexOf(store.company) === -1) {
          this.companyItems.push(store.company)
        }
      })
      if (!commons.isNull(this.companyItems) && this.companyItems.length > 0) {
        this.selectedCompany = this.companyItems.filter(
          (comp) => !commons.isNull(comp)
        )[0]
      } else {
        let fallBackCompany = this.$store.state.auth.user.company
        this.companyItems.push(fallBackCompany)
        this.selectedCompany = fallBackCompany
        if (commons.isNull(fallBackCompany)) {
          fallBackCompany = await this.$utils.getCompany(
            this.$store.state.auth.user.company
          )
        }
        this.companyItems.push(fallBackCompany)
        this.selectedCompany = fallBackCompany
        console.log(this.selectedCompany)
      }
    },
    setCountry () {
      this.countryItems = []
      this.searchStoreItems.forEach((row) => {
        if (this.countryItems.indexOf(row.country) === -1) {
          if (this.selectedCompany === row.company) {
            this.countryItems.push(row.country)
          }
        }
      })

      // Store
      if (this.permissionkey === 'store') {
        if (
          !commons.isNull(this.countryItems) &&
          this.countryItems.length > 0
        ) {
          this.selectedCountry = this.countryItems.filter(
            (comp) => !commons.isNull(comp)
          )[0]
        }
      }
    },
    setRegion () {
      this.searchStoreItems.forEach((row) => {
        if (this.regionItems.indexOf(row.region) === -1) {
          if (this.selectedCountry === row.country) {
            this.regionItems.push(row.region)
          }
        }
      })

      // Store
      if (this.permissionkey === 'store') {
        if (!commons.isNull(this.regionItems) && this.regionItems.length > 0) {
          this.selectedRegion = this.regionItems.filter(
            (comp) => !commons.isNull(comp)
          )[0]
        }
      }
      if (this.flagdata === 'country') {
        this.selectedRegion = ''
      }
    },
    setCity () {
      this.searchStoreItems.forEach((row) => {
        if (this.cityItems.indexOf(row.city) === -1) {
          if (this.selectedRegion === row.region) {
            this.cityItems.push(row.city)
          }
        }
      })

      // Store
      if (this.permissionkey === 'store') {
        if (!commons.isNull(this.cityItems) && this.cityItems.length > 0) {
          this.selectedCity = this.cityItems.filter(
            (comp) => !commons.isNull(comp)
          )[0]
        }
      }
      if (this.flagdata === 'region') {
        this.selectedCity = ''
      }
    },
    setStore () {
      this.searchStoreItems.forEach((row) => {
        if (this.selectedCity.indexOf(row.city) !== -1) {
          this.storeItems.push({ code: row.code, name: row.name })
        }
        console.log(this.storeItems)
      })

      // Store
      if (this.permissionkey === 'store') {
        if (!commons.isNull(this.storeItems) && this.storeItems.length > 0) {
          this.selectedStore = this.storeItems.filter(
            (comp) => !commons.isNull(comp)
          )[0]
        }
      }
      if (this.flagdata === 'city') {
        this.selectedStore = ''
      }
    }
  }
}
</script>

<style scoped>
.flexBox {
  display: flex;
  justify-content: space-between;
  max-width: 970px;
}
.flexBox > div {
  width: calc((100% - 40px) / 2);
}
/* ::-webkit-scrollbar {
  display: none;
} */
.select_store_box {
  display: flex;
  min-height: 40px;
  border: none !important;
  overflow: hidden;
}
.select_store_box .select_article {
  display: flex;
  justify-content: start !important;
  align-items: center;
  width: 27% !important;
  font-size: var(--font17);
  background: transparent !important;
  color: #4d4f5c;
  text-align: center;
  font-weight: bold !important;
}
.select_store_box .forms-area {
  display: flex;
  /* align-items: center; */
  width: 60%;
  font-size: var(--font16);
  border: 1px solid #808495;
  border-radius: 5px;
  color: #808495;
  overflow-y: auto;
}
.select_store_box .select_article.is-complete {
  /* background: #001e38; */
  /* color: #fff; */
}
.v-list-item-group .v-list-item--active {
  color: white;
  background: #001e38;
  width: 200px;
}
.className {
  pointer-events: none;
  opacity: 0.5;
}
.select_store_box .forms-area.list {
  align-items: flex-start;
  height: 240px;
}
.row-class:hover {
  cursor: pointer;
}
/* .active > .text-start {
  background: #031e37 !important;
  color: #ffffff !important;
} */
.customInfo .info-preview .preview {
  justify-content: center;
  align-items: center;
  height: 370px;
  background: #f6f5f5;
}
.customInfo .info-preview .tit {
  padding: 15px 10px !important;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 44px !important;
  transform: translateZ(0);
}
.slick-prev {
  left: 0 !important;
  position: absolute;
  z-index: 1;
}
.slick-next {
  right: 0 !important;
}
.slick-prev:before {
  background-image: url("~@/assets/img/previous.png");
  background-size: 20px 28px;
  display: inline-block;
  width: 50px;
  height: 50px;
  content: "";
  margin-left: 18px;
  margin-top: -8px;
}
.slick-next:before {
  background-image: url("~@/assets/img/next.png");
  background-size: 20px 28px;
  display: inline-block;
  width: 50px;
  height: 50px;
  content: "";
  margin-left: -24px;
  margin-top: -9px;
}
.slick-track {
  position: relative;
  top: 0;
  display: block;
  transform: translateZ(0);
  text-align: -webkit-center !;
}
.container {
  width: 100%;
  padding: 22px !important;
  margin-right: auto;
  margin-left: auto;
}
.slick-dots {
  position: absolute;
  bottom: 36px;
  right: 0;
  display: block;
  width: 100%;
  padding: 0 !important;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li button:before {
  font-size: 10px;
  line-height: 10px;
  color: #9c9898;
}

.slick-slide img {
  display: block;
  width: 80% !important;
  height: 169px !important;
}

.tagvalues {
  width: 80% !important;
  text-align: center !important;
  font-weight: bold !important;
  font-size: var(--font14);
  color: #4d4f5c !important;
  margin-top: 8px;
}
::v-deep .form-input.v-text-field--outlined {
  padding-top: 10px !important;
}
::v-deep tr.v-data-table__selected {
  background: #09263f !important;
}
::v-deep tr.v-data-table__selected td {
  color: #ffffff !important;
}
::v-deep tr.v-data-table__selected .mdi-checkbox-marked::before {
  color: #ffffff !important;
}
::v-deep .tit {
  background: #fafafa !important;
  color: black !important;
}
::v-deep .preview {
  background: grey lighten-8 !important;
}
::v-deep .pageInfotext {
  float: right !important;
  margin-top: -6px !important;
  padding: 14px !important;
  font-size: var(--font16);
  color: #808495;
  line-height: 1;
}
::v-deep .v-pagination.v-pagination {
  padding-left: 0 !important;
  justify-content: end !important;
}
</style>
