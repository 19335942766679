var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-template" }),
        _vm._v(_vm._s(_vm.$t("Image on Template")) + " ")
      ]),
      _c(
        "v-row",
        { staticClass: "mt-12" },
        [
          _c("v-col", { attrs: { cols: "4" } }, [
            _c(
              "h3",
              {
                staticClass: "page-sub-title-bar",
                staticStyle: { "font-weight": "bold" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("AREA LIST")) + " ")]
            ),
            _c("div", { staticClass: "select_store_box mt-5" }, [
              _c("h4", { staticClass: "select_article is-complete" }, [
                _vm._v(_vm._s(_vm.$t("Company")))
              ]),
              _c(
                "div",
                { staticClass: "forms-area" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.companyItems,
                      label: "" + _vm.$t("Select the company"),
                      "item-text": "companyItems.company",
                      "item-value": "companyItems.company",
                      disabled: _vm.disabledCompany,
                      "clearable:false": "",
                      dense: "",
                      flat: "",
                      "hide-details": "",
                      solo: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.changeedCompany()
                      }
                    },
                    model: {
                      value: _vm.selectedCompany,
                      callback: function($$v) {
                        _vm.selectedCompany = $$v
                      },
                      expression: "selectedCompany"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "select_store_box mt-5" }, [
              _c("h4", { ref: "country", staticClass: "select_article" }, [
                _vm._v(_vm._s(_vm.$t("Country")))
              ]),
              _c(
                "div",
                { staticClass: "forms-area" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.countryItems,
                      label: "" + _vm.$t("Select the country"),
                      "clearable:false": "",
                      dense: "",
                      flat: "",
                      "hide-details": "",
                      solo: "",
                      clearable: ""
                    },
                    on: {
                      change: function($event) {
                        _vm.changeedCountry()
                        _vm.Flagchange("country")
                      }
                    },
                    model: {
                      value: _vm.selectedCountry,
                      callback: function($$v) {
                        _vm.selectedCountry = $$v
                      },
                      expression: "selectedCountry"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "select_store_box" }, [
              _c("h4", { ref: "region", staticClass: "select_article" }, [
                _vm._v(_vm._s(_vm.$t("Region")))
              ]),
              _c(
                "div",
                { staticClass: "forms-area" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.regionItems,
                      label: "" + _vm.$t("Select the region"),
                      "clearable:false": "",
                      dense: "",
                      flat: "",
                      "hide-details": "",
                      solo: "",
                      clearable: ""
                    },
                    on: {
                      change: function($event) {
                        _vm.changeedRegion()
                        _vm.Flagchange("region")
                      }
                    },
                    model: {
                      value: _vm.selectedRegion,
                      callback: function($$v) {
                        _vm.selectedRegion = $$v
                      },
                      expression: "selectedRegion"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "select_store_box" }, [
              _c("h4", { ref: "city", staticClass: "select_article" }, [
                _vm._v(_vm._s(_vm.$t("City")))
              ]),
              _c(
                "div",
                { staticClass: "forms-area" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.cityItems,
                      label: "" + _vm.$t("Select the city"),
                      "clearable:false": "",
                      dense: "",
                      flat: "",
                      "hide-details": "",
                      solo: "",
                      clearable: ""
                    },
                    on: {
                      change: function($event) {
                        _vm.changeedCity()
                        _vm.Flagchange("city")
                      }
                    },
                    model: {
                      value: _vm.selectedCity,
                      callback: function($$v) {
                        _vm.selectedCity = $$v
                      },
                      expression: "selectedCity"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "select_store_box" }, [
              _c("h4", { ref: "store", staticClass: "select_article" }, [
                _vm._v(_vm._s(_vm.$t("Store")))
              ]),
              _c(
                "div",
                { staticClass: "forms-area list" },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item-group",
                        {
                          attrs: { "active-class": "list-select" },
                          model: {
                            value: _vm.selectedStore,
                            callback: function($$v) {
                              _vm.selectedStore = $$v
                            },
                            expression: "selectedStore"
                          }
                        },
                        _vm._l(_vm.storeItems, function(item, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              attrs: { value: item.code + "," + item.name },
                              on: {
                                change: function($event) {
                                  return _vm.changeedStore(item.code)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s(
                                        item.name + "(" + item.code + ")"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("v-row", [
                _c(
                  "div",
                  {
                    staticClass: "searchArea",
                    staticStyle: { "margin-top": "25px" }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input",
                      attrs: {
                        placeholder: _vm.$t("Input name"),
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        clearable: ""
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchGroups($event)
                        },
                        "click:clear": _vm.resetsearch
                      },
                      model: {
                        value: _vm.searchBy.fileName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchBy, "fileName", $$v)
                        },
                        expression: "searchBy.fileName"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn type-search ml-3",
                        attrs: { text: "" },
                        on: { click: _vm.searchGroups }
                      },
                      [_vm._v(_vm._s(_vm.$t("Search")))]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-data-table",
                {
                  staticClass: "type3 tbl-type01 mt-10",
                  staticStyle: { "margin-left": "-13px", "margin-top": "15px" },
                  attrs: {
                    headers: _vm.imageHeaders,
                    "item-key": "fileName",
                    items: _vm.initaldata,
                    "show-select": "",
                    options: _vm.options,
                    "hide-default-footer": true,
                    height: "420",
                    "item-class": _vm.getRowClass,
                    "items-per-page": 10,
                    "single-select": false
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.options = $event
                    },
                    "click:row": _vm.getImagePreview
                  },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("No data available")))])
                  ])
                ],
                2
              ),
              _c("div", { staticClass: "pageInfotext" }, [
                _vm._v(_vm._s(_vm.pageInfoText))
              ]),
              _c(
                "div",
                { staticStyle: { padding: "14px" } },
                [
                  _c("v-pagination", {
                    staticClass: "pageAlign",
                    attrs: { length: _vm.totalPages * 1, color: "#2f3b4c" },
                    on: { input: _vm.paging },
                    model: {
                      value: _vm.page,
                      callback: function($$v) {
                        _vm.page = $$v
                      },
                      expression: "page"
                    }
                  })
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: { "margin-left": "-20px", "margin-top": "-48px" }
                },
                [
                  _c(
                    "div",
                    [
                      _c("file-upload", {
                        staticClass: "btn-s ml-2",
                        attrs: {
                          imagedetail: _vm.imagedetail,
                          btnDisabledFileUpload: _vm.btnDisabledFileUpload
                        },
                        on: { callTorefreah: _vm.torefresh }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-s ml-2",
                          attrs: {
                            disabled:
                              _vm.selected.length === 0 ||
                              _vm.btnDisabledFileUpload,
                            text: ""
                          },
                          on: { click: _vm.Todeleteimages }
                        },
                        [_vm._v(_vm._s(_vm.$t("Delete")))]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "3" } }, [
            _c(
              "div",
              {
                staticClass: "customInfo",
                staticStyle: { "margin-top": "91px" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "info-preview",
                    staticStyle: { width: "480px" }
                  },
                  [
                    _c("h4", { staticClass: "tit" }, [
                      _vm._v(_vm._s(_vm.$t("PREVIEW")))
                    ]),
                    _c("div", { staticClass: "preview" }, [
                      _c("img", {
                        staticClass: "img-label",
                        attrs: { src: _vm.imageContent }
                      })
                    ])
                  ]
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }