<template>
  <v-dialog
    v-model="fileUploadVisible"
    width="800"
    persistent scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn-s ml-2"
        :disabled="btnDisabledFileUpload"
        v-bind="attrs"
        v-on="on"
        text
        >{{ $t("File Upload") }}</v-btn
      >
    </template>
    <v-card class="popup add_store_popup">
      <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-product"></i>{{ $t("File Upload") }}
      </h3>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-file-input
            outlined
            dense
            hide-details
            prepend-icon
            solo
            flat
            multiple
            accept="image/*"
            :placeholder="`${$t('Select file')}`"
            class="form-file"
            v-model="files"
            @change="appendFiles"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="label-txt">{{ $t('Selected Files') }}</p>
          <ul class="selectedFile-list mt-2">
            <li v-for="file in filesToUpload" :key="file.name">
              <p>{{ file.name }}</p>
              <v-btn @click="removeFile(file.name)" text icon
                ><img width="24" height="24" src="@/assets/img/ico-delete2.png"
              /></v-btn>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn text icon @click="uploadFiles()" :disabled="filesToUpload.length===0" class="btn">{{
          $t("Upload")
        }}</v-btn>
        <v-btn text icon @click="cancelFileUpload()" class="btn">{{
          $t("Cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus'
// import commons from '@/plugins/commons'
// Mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
import FileUploader from '@/mixins/FileUploader'
export default {
  mixins: [FileUploader, MsgBoxParent],

  props: {
    btnDisabledFileUpload: Boolean,
    imagedetail: Object
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      requests: {
        uploadFiles: {
          method: 'POST',
          url: '/api/common/images/register'
        }
      },
      files: null,
      filesToUpload: [],
      fileUploadVisible: false,
      finalimagearray: []
    }
  },
  computed: {
    tochangeflag () {
      return this.imagedetail
    }
  },
  watch: {
    tochangeflag () {
      console.log(this.imagedetail)
    }
  },
  mounted () {
    console.log(this.imagedetail)
  },
  methods: {
    cancelFileUpload () {
      this.files = null
      this.filesToUpload = []
      this.finalimagearray = []
      this.toggleFileUpload()
      this.$emit('callTorefreah')
    },
    toggleFileUpload () {
      this.fileUploadVisible = !this.fileUploadVisible
    },
    appendFiles () {
      this.filesToUpload = [...this.filesToUpload, ...this.files]
      this.files = []
    },
    removeFile (fileName) {
      this.filesToUpload = this.filesToUpload.filter(
        (file) => file.name !== fileName
      )
    },
    getBase64withFormat (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const result = reader.result
          resolve(result)
        }
        reader.onerror = (error) => reject(error)
      })
    },
    async buildFileUploadBody (files) {
      const fileDataList = []
      const fileData = {}
      fileData.contentType = 'IMAGE'
      fileData.pageIndex = 1
      fileData.fileName = files.name
      fileDataList.push(fileData)
      return fileDataList
    },
    async uploadFiles () {
      if (this.filesToUpload === null || this.filesToUpload === undefined) {
        EventBus.$emit('messageAlert', this.$t('Select files to upload.'))
        return
      }
      console.log(this.filesToUpload)
      let sumofImageSize = 0

      for (const fileforbase64 of this.filesToUpload) {
        sumofImageSize += parseInt(fileforbase64.size)
        const contentsBase64 = await this.getBase64withFormat(fileforbase64)
        const body = {
          content: contentsBase64,
          id: this.imagedetail.id,
          areaId: this.imagedetail.areaId,
          stationCode: this.imagedetail.stationCode,
          name: this.imagedetail.fileName,
          fileName: fileforbase64.name
        }
        this.finalimagearray.push(body)
        var fname = body.fileName
        var re = /(\.jpg|\.jpeg|\.bmp|\.png)$/i
        if (!re.exec(fname)) {
          EventBus.$emit(
            'messageAlert',
            this.$t('This extension is not supported.')
          )
          this.finalimagearray = []
          return
        }
      }
      if (sumofImageSize >= 20971520) {
        EventBus.$emit(
          'messageAlert',
          this.$t('The file size should not exceed 20 mb.')
        )
        return
      }

      const params = {}
      const config = { params: params }
      if (this.productionType === 'cloud') {
        params.company = this.$store.state.auth.user.company
      }
      this.$utils
        .callAxiosWithBody(
          this.requests.uploadFiles.method,
          this.requests.uploadFiles.url,
          this.finalimagearray,
          config
        )
        .then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.cancelFileUpload()
        })
        .catch((error) => {
          switch (error.response.status) {
            default:
              EventBus.$emit(
                'messageAlert',
                this.$t(error.response.data.responseMessage)
              )
              break
          }
          this.cancelFileUpload()
        })
    }
  }
}
</script>

<style></style>
