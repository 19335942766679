var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", persistent: "", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn-s ml-2",
                      attrs: { disabled: _vm.btnDisabledFileUpload, text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("File Upload")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.fileUploadVisible,
        callback: function($$v) {
          _vm.fileUploadVisible = $$v
        },
        expression: "fileUploadVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-product" }),
              _vm._v(_vm._s(_vm.$t("File Upload")) + " ")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-file-input", {
                        staticClass: "form-file",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          "prepend-icon": "",
                          solo: "",
                          flat: "",
                          multiple: "",
                          accept: "image/*",
                          placeholder: "" + _vm.$t("Select file")
                        },
                        on: { change: _vm.appendFiles },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("img", {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    src: require("@/assets/img/ico-upload-file.gif")
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.files,
                          callback: function($$v) {
                            _vm.files = $$v
                          },
                          expression: "files"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("p", { staticClass: "label-txt" }, [
                      _vm._v(_vm._s(_vm.$t("Selected Files")))
                    ]),
                    _c(
                      "ul",
                      { staticClass: "selectedFile-list mt-2" },
                      _vm._l(_vm.filesToUpload, function(file) {
                        return _c(
                          "li",
                          { key: file.name },
                          [
                            _c("p", [_vm._v(_vm._s(file.name))]),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeFile(file.name)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    src: require("@/assets/img/ico-delete2.png")
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: {
                    text: "",
                    icon: "",
                    disabled: _vm.filesToUpload.length === 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.uploadFiles()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Upload")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancelFileUpload()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }